<template>
  <div class="st-container" v-if="isLanding">
    <LandingBanner :dealTypeCode="dealTypeCode"/>
    <!-- <LandingDeal v-if="landingDealData.length>0" v-model="dealTypeCode"/> -->
    <Lesiurtabs0
      :dealData="dealData"
      :dealType="dealType"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      :showBanner="showMiddleBanner"
      v-if="dealData.length > 0 && device==='desktop'" />
    <Lesiurtabs2
      :dealData="dealData"
      :dealType="dealType"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      :showBanner="showMiddleBanner"
      v-if="dealData.length > 0 && device==='mobile'" />
    <ContentLoading v-if="dealData.length === 0" type="deal" />
  </div>
  <div class="st-container" v-else-if="isLandingChain">
    <LandingChainBanner :chainName="chainName" v-if="false"/> <!-- disable temporary 2024.06.20 -->
    <!-- <LandingDeal v-if="landingDealData.length>0" v-model="dealTypeCode"/> -->
    <Lesiurtabs0
      :dealData="dealChainData"
      :dealType="dealType"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      :showBanner="showMiddleBanner"
      v-if="dealChainData.length > 0 && device==='desktop'" />
    <Lesiurtabs2
      :dealData="dealChainData"
      :dealType="dealType"
      :banner="middleBanner"
      :bannerHref="middleBannerHref"
      :showBanner="showMiddleBanner"
      v-if="dealChainData.length > 0 && device==='mobile'" />
    <ContentLoading v-if="dealChainData.length === 0" type="deal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'LandingContent',
  mixins: [getBannerImage],
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      dealData: 'GET_LANDING_DEAL_DATA',
      dealType: 'GET_DEAL_TYPE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      dealChainData: 'GET_LANDING_DEAL_CHAIN_DATA',
    }),
    dealTypeCode() {
      const { query } = this.$route;
      return query.destination || '';
    },
    chainName() {
      const { query } = this.$route;
      return query.chain || '';
    },
  },
  components: {
    LandingBanner: () => import('@/components/landing/LandingBanner'),
    // LandingDeal: () => import('@/components/landing/LandingDeal'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Lesiurtabs0: () => import('@/components/home/lesiurtabs/LesiurtabsTheme0'),
    Lesiurtabs2: () => import('@/components/home/lesiurtabs/LesiurtabsTheme2'),
    LandingChainBanner: () => import('@/components/landing/LandingChainBanner'),
  },
};
</script>

<style>
@media (min-width: 1200px){
  .container {
    max-width: 1500px;
  }
}
</style>
